/* right side rectangles */

.hero-right-side {
  position: absolute;
  background: var(--red);
  box-shadow: 0px 0px 150px 120px var(--red);
  right: 0;
  top: 450px;
  transform: rotate(90deg);
}

/* green glowing circle */

.bg-eclipse {
  border-radius: 50%;
  z-index: -10;
  position: absolute;
  width: 500px;
  height: 500px;
  right: -265px;
  top: -488px;
  background: var(--red);
  mix-blend-mode: normal;
  box-shadow: 0px 0px 150px 162px var(--red);
  z-index: -10;
}

/* small horizontal rectangle */

.sm-rectangle {
  position: absolute;
  width: 250px;
  height: 126px;
  top: -50%;
  right: -75px;
  background-color: rgba(54, 53, 53, 0.45);
  box-shadow: 0px 0px 5px 5px rgb(0 0 0 / 15%);
  border-radius: 10px;
}

/* large vertical rectangle */

.lg-rectangle {
  position: absolute;
  width: 97px;
  height: 550px;
  top: -260px;
  right: -60px;
  background-color: rgba(49, 48, 48, 0.4);
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

}

/* medium rectangle with search bar and circles */

.md-rectangle {
  position: absolute;
  width: 202px;
  height: 359px;
  top: -150px;
  right: -90px;
  background-color: rgba(64, 64, 64, 0.5);
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

}


/* lg-rectangle animation */

.slide-in-top {
  animation: slide-in-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-in-top {
  0% {
    transform: translateX(-1000px) rotate(90deg);
    opacity: 0;
  }

  100% {
    transform: translateX(0) rotate(90deg);
    opacity: 1;
  }
}

/* md-rectangle animation */



.scale-in-hor-left {
  animation: scale-in-hor-left 1.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes scale-in-hor-left {

  0% {
    transform: rotate(90deg) translateX(100vw);
    transform-origin: 50% 50%;
    opacity: 0;
  }

  100% {
    transform: rotate(90deg) translateX(0px);
    transform-origin: 50% 50%;
    opacity: 1;
  }
}

/* sm-rectangle animation */

.slide-in-right {
  animation: slide-in-right 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-in-right {
  0% {
    transform: translateY(-1000px) rotate(90deg);
    opacity: 0;
  }

  100% {
    transform: translateY(0) rotate(90deg);
    opacity: 1;
  }
}

/* search bar, circles */

.green-circle {
  background: rgba(38, 170, 38, 0.2);
  position: absolute;
  width: 8px;
  height: 8px;
  left: 165px;
  top: 340px;
  border-radius: 10px;
  z-index: 10;
}

.red-circle {
  background: rgba(178, 18, 18, 0.2);
  position: absolute;
  width: 8px;
  height: 8px;
  left: 185px;
  top: 340px;
  border-radius: 10px;
  z-index: 10;
}

.yellow-circle {
  background: rgba(236, 170, 0, 0.2);
  position: absolute;
  width: 8px;
  height: 8px;
  left: 175px;
  top: 340px;
  border-radius: 10px;
  z-index: 10;
}

.search-bar {
  position: absolute;
  width: 90px;
  height: 8px;
  left: 71px;
  top: 340px;
  background: rgba(130, 128, 128, 0.3);
  border-radius: 10px;
}

@media (max-width: 480px) {

  .hero-right-side {
    position: absolute;
    right: 0;
    top: 320px;
    transform: rotate(90deg) scale(0.7);
  }

  .md-rectangle {
    right: -130px;
  }

  .sm-rectangle {
    right: -100px;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .hero-right-side {
    position: absolute;
    right: 0;
    top: 360px;
    transform: rotate(90deg) scale(0.7);
  }
}