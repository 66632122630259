.services {
  padding: var(--tablet-padding);
  margin: 10rem 0;
  color: var(--primary-text-color);
  display: flex;
}

.services-desc {
  width: 100%;
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 15rem;
  margin-top: 10rem;
}

.services-desc h2 {
  font-size: var(--font-xl);
  margin-bottom: 1.1rem;
}

.services-desc h3 {
  font-size: var(--font-md);
  margin-bottom: 1.1rem;
}

.services-desc p {
  width: 55%;
  margin-bottom: 2.5rem;
  line-height: 1.6;
  font-size: var(--font-sm);
  font-weight: 300;
}

.services-desc button {
  width: 20%;
}

.services-btn {
  font-family: 'Poppins', sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  padding: 10px 16px;
  border-radius: 25px;
  border: none;
  box-shadow: 0px 0px 20px 7px var(--red);
  color: var(--primary-text-color);
  background-color: var(--primary-bg-color);
  background-image: linear-gradient(270deg, var(--red) 50%, transparent 50%);
  background-size: 1100px;
  background-position: 100%;
  transition: background 300ms ease-in-out;
}

.services-btn-overlay:hover {
  background-position: 0%;
  box-shadow: 0px 0px 20px 7px var(--primary-text-color);
  color: var(--primary-text-color);
}

.services-in-left-port-btn {
  animation: scale-in-left-services-btn 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s both;
}

@keyframes scale-in-left-services-btn {
  0% {
    transform: scale(0);
    transform-origin: 0% 50%;
    opacity: 1;
  }

  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
    opacity: 1;
  }
}

/* screens on the right */

.services-screens {
  transform: scale(0.8);
  position: relative;
  top: 200px;
  right: 140px;
}

.phone {
  position: absolute;
  width: 35px;
  height: 75px;
  left: -26rem;
  top: 4rem;
  background: rgba(217, 217, 217, 0.07);
  box-shadow: 0px 0px 5px 10px rgba(244, 37, 37, 0.4);
  border-radius: 10px;
  transform: matrix(0.97, 0.22, 0, 1, 0, 0);
}

.tablet {
  position: absolute;
  width: 100px;
  height: 124px;
  left: -32rem;
  top: 9rem;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 0px 10px 10px rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  transform: matrix(0.97, 0.22, -0.7, 0.71, 0, 0);
}

.desktop {
  position: absolute;
  width: 260px;
  height: 180px;
  right: 12rem;
  top: -4rem;
  background: rgba(217, 217, 217, 0.07);
  box-shadow: 0px 0px 30px 4px rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  transform: matrix(0.97, 0.22, 0, 1, 0, 0);
}

.laptop-keyboard {
  position: absolute;
  width: 170px;
  height: 100px;
  left: -23.8rem;
  top: 7.2rem;
  background: rgba(164, 164, 164, 0.07);
  border-radius: 10px;
  transform: matrix(0.97, 0.22, -0.71, 0.7, 0, 0);
}

.laptop-screen {
  position: absolute;
  width: 170px;
  height: 130px;
  left: -21.8rem;
  top: 0rem;
  background: rgba(244, 37, 37, 0.04);
  box-shadow: 0px 0px 50px 10px rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  transform: matrix(0.97, 0.22, -0.02, 1, 0, 0);
}

/* vibration phone animation */

.vibrate-1 {
  animation: vibrate-1 0.5s cubic-bezier(1.000, 0.000, 0.000, 1.000);
}

@keyframes vibrate-1 {
  0% {
    transform: matrix(0.97, 0.22, 0, 1, 0, 0);
  }

  7.14% {
    transform: matrix(0.97, 0.22, 0, 1, -2, -2);
  }

  14.28% {
    transform: matrix(0.97, 0.22, 0, 1, -4, -4);
  }

  21.42% {
    transform: matrix(0.97, 0.22, 0, 1, -4, -2);
  }

  28.56% {
    transform: matrix(0.97, 0.22, 0, 1, -4, -4);
  }

  35.70% {
    transform: matrix(0.97, 0.22, 0, 1, -2, -2);
  }

  44.28% {
    transform: matrix(0.97, 0.22, 0, 1, -4, -4);
  }

  58.42% {
    transform: matrix(0.97, 0.22, 0, 1, -4, -2);
  }

  75.56% {
    transform: matrix(0.97, 0.22, 0, 1, -4, -4);
  }

  86.70% {
    transform: matrix(0.97, 0.22, 0, 1, -2, -2);
  }

  100% {
    transform: matrix(0.97, 0.22, 0, 1, 0, 0);
    animation-timing-function: cubic-bezier(1.000, 0.000, 0.000, 1.000);
  }
}

/* tools icons */

.tools-container {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
}

.tools-container h2 {
  font-size: var(--font-md);
  margin-bottom: 2rem;
}

.tools-icons {
  color: white;
  font-size: 50px;
  margin-right: 4.5rem;
  margin-bottom: 3.5rem;
}

.tools-icons-container {
  display: flex;
  justify-content: space-between;
  font-size: 50px;
}

@media (max-width: 480px) {

  .services {
    padding: 0 var(--mobile-padding)
  }

  .services-desc {
    margin-bottom: 0rem;
    margin-top: 5rem;
  }

  .services-desc h2 {
    font-size: 1.6rem;
  }

  .tools-container h2 {
    font-size: 1rem;
  }

  .services-desc h3 {
    font-size: 1rem;
  }

  .services-desc p {
    font-size: 0.8rem;
    width: 100%;
    margin-bottom: -2.5rem;
  }

  .services-btn {
    display: none;
  }

  .services-screens {
    left: 7rem;
  }

  .services-screens .phone {
    display: block;
    left: -10rem;
    top: 7.2rem;
    width: 42px;
    height: 75px;
  }

  .tools-icons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1rem;
    margin-bottom: 0rem;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .services {
    padding: var(--tablet-padding)
  }

  .services-screens {
    left: 50px
  }

  .services-desc {
    margin: 0;
    margin-bottom: 0;
  }

  .services-desc p {
    width: 100%;
  }

  .services-btn {
    width: 25%;
  }

  .services-desc button {
    width: 40%;
  }

  .phone {
    display: none;
  }

  .tools-icons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1rem;
    margin-bottom: 3rem;
  }

}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .services {
    padding: var(--tablet-padding);
  }

  .services-desc {
    margin-bottom: 0;
  }

  .services-screens {
    left: 50px
  }

  .services-desc p {
    width: 70%;

  }

  .services-desc button {
    width: 25%;
  }
}