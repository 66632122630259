.swiper,
.swiper-container,
.swiper-wrapper {
  overflow: visible !important;
  position: relative !important;
}

.swiper-slide,
.swiper-slide-prev,
.swiper-slide-next {
  position: relative !important;
}

.swiper-button-next,
.swiper-button-prev {
  height: 500px !important;
  width: 29vw !important;
  top: 2vh !important;
}

.swiper-button-next {
  right: -25vw !important;
}

.swiper-button-prev {
  left: -25vw !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  content: "" !important;
  font-family: none;
}

.slide-desc {
  width: 50%;
  text-align: left;
  background-color: rgb(0 0 0 / 25%);
  border-radius: 8px;
  padding: 10px 20px;
  z-index: 1;
  margin: 0 auto;
}

.slide-desc h2 {
  font-size: var(--font-md);
  font-weight: 400;
}

.slide-desc h3 {
  font-size: var(--font-sm);
  font-weight: 300;
  margin-bottom: 10px;
}

.slide-desc a {
  font-size: var(--font-sm);
  font-weight: 300;
  text-decoration: underline;
  text-underline-offset: 4px;
}

.swiper-slide-prev img,
.swiper-slide-next img,
.swiper-slide img {
  filter: blur(8px);
  height: 30rem;
  width: 52rem;
  object-fit: cover;
  border-radius: 8px;
  position: relative !important;
}

.swiper-slide-prev .slide-desc,
.swiper-slide-next .slide-desc {
  filter: blur(8px);
}

.swiper-slide-prev img {
  margin-right: -700px;
}

.swiper-slide-next img {
  margin-left: -700px;
}

.swiper-slide.swiper-slide-next {
  z-index: -1;
}

.swiper-slide-active img {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: 30rem;
  width: 52rem;
  object-fit: cover;
  filter: blur(0);
  z-index: 1;
}

.swiper-pagination {
  display: none;
  z-index: -2 !important;
}

@media (max-width: 480px) {

  .swiper-slide img {
    height: 15rem;
    width: 100%;
  }

  .swiper-slide-prev img,
  .swiper-slide-next img,
  .swiper-slide-active img {
    margin-right: 0px;
    margin-left: 0;
    height: 15rem;
  }

  .slide-desc {
    width: 100%;
    left: 4.9rem;
    bottom: -18.8rem;
  }

  .swiper-pagination {
    display: block;
    z-index: -10;
  }

  .swiper-pagination-bullet {
    position: relative;
    top: 40px;
    margin: 5px 5px;
    transition: 0.2s;
  }

  .swiper-pagination-bullet-active {
    background: #ffffff8f !important;
    opacity: 0.5;
  }

  .swiper-button-next,
  .swiper-button-prev {
    height: 370px !important;
    width: 22vw !important;
  }

}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .slide-desc {
    width: 80%;
  }

  .swiper-slide img {
    height: 20rem;
    width: 100%;
  }

  .swiper-slide-prev img,
  .swiper-slide-next img,
  .swiper-slide-active img {
    margin-right: 0px;
    margin-left: 0;
    height: 20rem;
  }

  .swiper {
    margin-bottom: 2rem;
  }

  .swiper-pagination {
    display: block;
    z-index: -10;
  }

  .swiper-pagination-bullet {
    position: relative;
    top: 40px;
    margin: 5px 5px;
    transition: 0.2s;
  }

  .swiper-pagination-bullet-active {
    background: #ffffff8f !important;
    opacity: 0.5;
  }

  .swiper-button-next,
  .swiper-button-prev {
    height: 340px !important;
    width: 22vw !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .slide-desc {
    width: 80%;
  }

  .swiper-pagination {
    display: block;
    z-index: -10;
  }

  .swiper-pagination-bullet {
    position: relative;
    top: 50px;
    margin: 5px 5px;
    transition: 0.2s;
  }

  .swiper-pagination-bullet-active {
    background: #ffffff8f !important;
    opacity: 0.5;
  }

  .swiper-button-next,
  .swiper-button-prev {
    height: 500px !important;
    width: 23vw !important;
  }
}

@media screen and (min-width: 1080px) {

  .swiper-button-next,
  .swiper-button-prev {
    height: 500px !important;
    width: 30vw !important;

  }

  .swiper-slide-prev img {
    position: absolute;
    right: 0;

  }

  .swiper-slide-next img {
    position: absolute;
    left: 0;
  }
}