:root {
  --primary-text-color: #ffffff;
  --primary-bg-color: #1D1C1C;
  --green: #55fc7bb5;
  --red: #F23221;
  --yellow: #eca400;
  --tablet-padding: 3rem;
  --mobile-padding: 1rem;

  /* font-sizes */

  /* about cards p */
  --font-xs: 14px;
  /* paragraph */
  --font-sm: 16px;
  /* nav, buttons */
  --font-md: 21px;
  /* section names */
  --font-lg: 28px;
  /* text headers */
  --font-xl: 37px;
  /* hero */
  --hero-h1: 4.9rem;
}