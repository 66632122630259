.contacts {
  padding: var(--tablet-padding);
  padding-top: 0;
  color: var(--primary-text-color);
}

.contacts-container {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.contacts-location img {
  min-width: 320px;
}

.contacts-location p {
  font-weight: 300;
  font-size: 14px;
  margin-left: 8%;
}

.contacts-contacts {
  flex-basis: 300px;
  margin-top: 31px;
}

.contacts-contacts h2 {
  text-align: left;
  font-size: var(--font-xl);
  font-weight: 400;
  margin-bottom: 2rem;
  margin-left: 7%;
}

.email-phone {
  width: 280px;
  margin: 0 auto;
  border: 1px solid var(--primary-text-color);
  border-radius: 15px;
  margin-bottom: 3.5rem;
  font-size: var(--font-sm);
}

.email-phone>div {

  display: flex;
  align-items: center;
}

.email-phone>div a {
  margin-left: 10%;
}

.email-phone div:last-of-type {
  border-top: 1px solid var(--primary-text-color);
}

.email-phone a {
  display: flex;
  text-align: center;
}

.contacts-icon {
  font-size: 2.2rem;
  border: 1px solid var(--primary-text-color);
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 5px;
}

/* footer form */

.contacts-form-container {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.contacts-form {
  width: 100%;
  margin-top: 8.5rem;
}

.contacts-form label {
  font-size: var(--font-sm);
}

.contacts-form form {
  background-color: #313131;
  padding: 30px 43px 43px 43px;
  border-radius: 15px;
  height: 400px;
}

.contacts-form form input {
  margin-top: 2px;
  height: 2.5rem;
  min-width: 100%;
  background-color: rgba(217, 217, 217, 0.11);
  border-radius: 10px;
  border: none;
  outline: none;
  color: #D9D9D9;
  font-size: var(--font-sm);
  font-family: 'Poppins';
  font-weight: 400;
}

.form-error {
  font-weight: 300;
  color: var(--red);
}

/* send form button */

.hide-send-button {
  margin-top: 2.22rem;
  opacity: 0;
}

.send-form-button {
  margin-top: 1.82rem;
  width: 110px;
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-md);
  border-radius: 25px;
  border: none;
  color: var(--primary-text-color);
  background-color: var(--yellow);
  background-image: linear-gradient(270deg, var(--red) 50%, transparent 50%);
  background-size: 300px;
  background-position: 100%;
  transition: background 300ms ease-in-out;
}

.send-form-btn-overlay:hover {
  background-position: 0%;
  color: var(--primary-bg-color);
}

.send-form-left-port-btn {
  animation: send-form-left-services-btn 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s both;
}

@keyframes send-form-left-services-btn {
  0% {
    transform: scale(0);
    transform-origin: 0% 50%;
    opacity: 1;
  }

  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
    opacity: 1;
  }
}


/* white horizontal line and everything below */

.contacts-hr {
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid var(--primary-text-color);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 1rem;
}

.contacts-hr p {
  font-size: 10px;
  text-align: left;
  font-weight: 300;
}

.contacts-hr a {
  font-size: 2rem;
}

.switch-container {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-flicker-in-glow {
  animation: text-flicker-in-glow 4s linear both;
}



.date-time-btn {
  width: 150px;
  position: absolute;
  right: 0;
  bottom: -4rem;
}

.form-success {
  width: 140px;
  color: #313131;
  background-image: linear-gradient(270deg, var(--green) 50%, transparent 50%);
  background-color: var(--yellow);
}

@keyframes text-flicker-in-glow {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  10.1% {
    opacity: 1;
  }

  10.2% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  20.1% {
    opacity: 1;
  }

  20.6% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  30.1% {
    opacity: 1;
  }

  30.5% {
    opacity: 1;
  }

  30.6% {
    opacity: 0;
  }

  45% {
    opacity: 0;
  }

  45.1% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  55% {
    opacity: 1;
  }

  55.1% {
    opacity: 0;
  }

  57% {
    opacity: 0;
  }

  57.1% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  60.1% {
    opacity: 0;
  }

  65% {
    opacity: 0;
  }

  65.1% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  75.1% {
    opacity: 0;
  }

  77% {
    opacity: 0;
    text-shadow: none;
  }

  77.1% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  85.1% {
    opacity: 0;
  }

  86% {
    opacity: 0;
  }

  86.1% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@media(max-width: 480px) {

  .contacts-form input {
    width: 100%;
  }

  .contacts {
    display: flex;
    flex-direction: column;
    padding: 0 var(--mobile-padding);
  }

  .contacts-contacts h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    margin-left: 0;
    font-size: var(--font-lg);
  }

  .contacts-container {
    display: block;
    margin-bottom: 0rem;
  }

  .email-phone {
    width: 100%;
    margin: 0;
  }

  .send-form-button {
    right: 0;
    bottom: -9.5rem;
    margin: 0.93rem 0;
  }

  .contacts-form-container {
    width: 100%;
    flex-direction: column;
  }

  .contacts-form {
    margin-top: 0.2rem;
  }

  .contacts-location,
  .contacts-location p {
    display: none;
  }

  .date-time-btn {
    bottom: -4.2rem;
  }

}

@media (min-width: 481px) and (max-width: 768px) {

  .contacts {
    display: flex;
    flex-direction: column;
    padding: var(--tablet-padding);
  }


  .contacts-contacts {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;

  }

  .contacts-contacts h2 {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: var(--font-lg);
  }

  .contacts-container {
    display: block;
    margin-bottom: 0rem;
  }

  .email-phone {
    margin: 0;
  }

  .send-form-button {
    right: 0;
    bottom: -9.5rem;
    margin: 0.9rem 0;
  }

  .contacts-form-container {
    width: 100%;
    flex-direction: column;
  }

  .contacts-form {
    margin-top: 0.4rem;
  }

  .contacts-location,
  .contacts-location p {
    display: none;
  }

  .date-time-btn {
    bottom: -4.2rem;
  }

}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .contacts {
    display: flex;
    flex-direction: column;
    padding: var(--mobile-padding);
  }

  .contacts-contacts h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    margin-left: 0;
    font-size: var(--font-lg);
  }

  .contacts-container {
    display: block;
    padding-top: 6rem;
    margin-bottom: 0rem;
  }

  .email-phone {
    width: 100%;
    margin: 0;
  }

  .contacts-form-container {
    width: 100%;
    flex-direction: column;
  }

  .contacts-form {
    margin-top: 2rem;
  }

  .send-form-button {
    margin: 0.9rem 0;
  }

  .contacts-location,
  .contacts-location p {
    display: none;
  }

  .date-time-btn {
    bottom: -4.2rem;
  }
}