* {
  box-sizing: border-box;

}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #1D1C1C;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
}

ul {
  list-style: none;
}

input,
textarea,
select {
  font-size: 16px;
  padding: 0.3em 0.8em;
  border-radius: 4px;
  border: 1px solid #777;
  display: block;
  max-width: 300px;
  margin-bottom: 1rem;
}

textarea {
  min-height: 5rem;
  min-width: 500px;
  resize: vertical;
}

button {
  font-size: 16px;
  padding: 0.3em 0.8em;
  border-radius: 4px;
  border: 1px solid #777;
  cursor: pointer;
  font-family: 'Poppins';
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-weight: 400;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
}