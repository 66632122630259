.react-switch-checkbox {
  height: 0;
  width: 100%;
  position: absolute;
  visibility: hidden;

}

.react-switch {
  border-radius: 15px;
  font-weight: 400;
  background-color: #D9D9D9;
  color: var(--primary-bg-color);
  cursor: pointer;
  width: 100%;
  height: 50px;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch .react-switch-button {
  border-radius: 15px;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: -1px;
  width: 50%;
  height: 50px;
  transition: 0.2s;
  background: #313131;
}

.react-switch-checkbox:checked+.react-switch-button {
  left: calc(100% - 49.8%);
  transform: translateX(-100% -2px);
}


.react-switch-checkbox:active+.react-switch-button {
  width: 50%;
}

.react-switch-labels {
  display: relative;
  z-index: 0;
  height: 100%;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 20px;
}

.react-switch-labels span {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
}

.react-switch-labels span:first-child {
  color: var(--primary-text-color)
}

.react-switch-labels span:last-child {
  right: 0;
}

.react-switch-labels>span {
  z-index: 1;
}

@media (max-width: 480px) {
  .react-switch-labels span {
    font-size: 1rem;
  }

}