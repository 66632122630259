.about {
  color: var(--primary-text-color);
  text-align: left;
  padding: var(--tablet-padding);
  position: relative;
  z-index: 0;
  padding-top: 9rem;
}

.mobile-h5 {
  display: none;
}

.about h2 {
  font-size: var(--font-lg);
  font-weight: 400;
  margin-bottom: 2rem;
}

.about-cards {
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
  margin-bottom: 16rem;
}

.single-about-card img {
  width: 190px;
  margin-bottom: 0.5rem;
  border-radius: 10px;
}

.about-socials-icon {
  font-size: 30px;
  margin-right: 0.5rem;
}

.single-about-card {
  font-weight: 400;
  border-left: 1px solid white;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.single-about-card h4 {
  font-size: var(--font-sm);
  font-weight: 300;
  margin-bottom: 0.3rem;
}


.single-about-card h4 {
  font-size: var(--font-sm);
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.single-about-card p {
  font-size: var(--font-xs);
  line-height: 1.6;
  margin-bottom: 1rem;
  font-weight: 300;
}


@media(max-width: 480px) {


  .about {
    padding: 0 var(--mobile-padding);
  }

  .about-cards {
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .single-about-card {
    gap: 0;
    margin-bottom: 1rem;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 0;
    border: none;
  }

  .single-about-card .single-card-desc {
    margin-left: 2rem;
  }

  .single-card-desc {
    min-width: 180px;
  }

  .single-about-card p {
    display: none;
  }

  .single-about-card img,
  .single-about-card {
    max-width: 110px;
    min-width: 110px;
  }

  .single-about-card h4 {
    font-weight: 400;
  }

  .about h2 {
    font-size: var(--font-md);
  }

}

@media screen and (min-width: 481px) and (max-width: 929px) {

  .about {
    padding: var(--tablet-padding);
  }

  .about-cards {
    flex-direction: column;
    margin-bottom: 3rem;
  }

  .single-about-card {
    max-width: 100px;
    gap: 0;
    margin-bottom: 1rem;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 0;
    border: none;
  }

  .single-about-card .single-card-desc {
    margin-left: 2rem;
  }

  .single-about-card p {
    display: none;
  }

  .single-card-desc {
    min-width: 180px;
  }

  .services-desc {
    padding-top: 4rem;
  }

}

@media screen and (min-width: 930px) and (max-width: 1210px) {
  .single-about-card {
    max-width: 190px;
  }

  .single-about-card img {
    min-width: 140px;
  }

}

@media screen and (min-width: 1211px) and (max-width: 1330px) {
  .about {
    padding: var(--tablet-padding);
  }

}