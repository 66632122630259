.portfolio-container {
  padding: var(--tablet-padding);
  padding-bottom: 15rem;
  color: var(--primary-text-color);
  margin-bottom: 5rem;
}

.portfolio-desc {
  padding-top: 5rem;
  text-align: center;
  margin-bottom: 2rem;
}

.portfolio-title {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.portfolio-desc h2 {
  padding-top: 42px;
  font-weight: 400;
  font-size: var(--font-md);
}

.portfolio-cards {
  margin-top: 10rem;
}

.single-portfolio-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10rem;
}

.single-portfolio-card img {
  max-height: 380px;
  width: 600px;
  object-fit: cover;
  box-shadow: 0px 0px 15px 10px rgb(0 0 0 / 54%);
}

.portfolio-info {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 3rem;
  flex-basis: 40%;
}

.portfolio-info h4 {
  font-weight: 500;
  font-size: 2rem;
}

.portfolio-info h5 {
  font-weight: 500;
  font-size: 1.5rem;
}

.portfolio-info a {
  font-weight: 500;
  font-size: 1rem;
  text-decoration: underline;
  text-underline-offset: 8px;
}

/* media breakpoints */

@media(max-width: 480px) {

  .portfolio-container {
    padding: var(--mobile-padding);
  }

  .portfolio-btn {
    font-size: 1rem;
  }

  .portfolio {
    padding: var(--mobile-padding);
  }

  .portoflio-title h2 {
    text-align: left;
    font-size: 1.6rem;
  }

  .portfolio-title h3 {
    font-size: 1rem;
  }

  .portfolio-desc {
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    margin-bottom: 3rem;
    padding-top: 0rem;
  }

  .portfolio-desc h2 {
    margin-top: 5rem;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {

  .portfolio-container {
    padding: 0;
    padding-bottom: 0rem;
    margin-bottom: 5rem;
  }

  .portfolio {
    padding: var(--tablet-padding);
    margin-bottom: 5rem;
  }

  .portfolio-desc {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .portfolio-title h3 {
    font-size: var(--font-md);
    margin-bottom: 1.1rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {

  .portfolio-container {
    margin-bottom: 0;
    padding-bottom: 5rem;
  }

  .portfolio {
    margin-bottom: 5rem;
    padding: var(--tablet-padding);
  }

  .portfolio-desc {
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }

  .portoflio-title {
    text-align: left;
  }

  .portfolio-title h3 {
    font-size: var(--font-md);
    margin-bottom: 1.1rem;
  }
}