.rdtPicker {
  color: var(--primary-text-color);
  position: unset;
  background-color: rgba(217, 217, 217, 0.11);
  border: none;
  margin-top: 15px;
  border-radius: 10px;
}

.rdt {
  padding: 0.115rem 0rem;
}

.rdtTime {
  padding: 3.95rem 2rem;
}

.rdtCounter .rdtCount {
  height: 30%;
}

.rdtCounterSeparator {
  position: relative;
  top: 0.3rem;
}

.rdtMonths,
.rdtYears {
  padding: 2.5rem;
}

.rdtPicker thead {
  font-family: 'Poppins';
}

.rdtPicker thead tr:first-of-type th:hover,
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtActive,
.rdtPicker .rdtTimeToggle:hover,
.rdtCounter .rdtBtn:hover {
  background: var(--primary-bg-color);
  border-radius: 10px;
}

.rdtSwitch,
.rdtPicker .dow {
  font-weight: 300;
}

.rdtSwitch:hover,
td.rdtMonth:hover,
td.rdtYear:hover,
.rdtPicker td.rdtActive:hover {
  cursor: pointer;
  background: var(--primary-bg-color);
  border-radius: 10px;
}

.rdt input {
  color: var(--primary-text-color);
  width: 100%;
  background-color: rgba(217, 217, 217, 0.11);
  margin: 0 auto;
  margin-bottom: 1rem;
  outline: none;
  border: none;
  border-radius: 10px;
}

.date-time-wrapper {
  position: relative;
}

.date-time {
  display: flex;
  flex-direction: column-reverse;
  gap: 0.2rem;
}

.date-time-inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: -8px;
}

.date-time-inputs input {
  min-width: 100%;
  background-color: rgba(217, 217, 217, 0.11);
  border-radius: 10px;
  border: none;
  outline: none;
  color: #D9D9D9;
  font-size: var(--font-sm);
  font-family: 'Poppins';
  font-weight: 400;
}

.call-form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.call-form-container form {
  padding: 20px 43px;
  width: 100%;
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  height: 400px;
}

@media(max-width: 480px) {

  .call-form-container form {
    flex-direction: column;
    height: 100%;
    padding-top: 38px;
    gap: 0.5rem;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .call-form-container form {
    flex-direction: column;
    height: 100%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1100px) {
  .call-form-container form {
    gap: 1rem;
  }
}