.hero {
  min-height: 100vh;
  display: flex;
  padding: var(--tablet-padding);
  position: relative;
}

.hero-title {
  margin-top: 10rem;
  color: var(--primary-text-color);
  text-align: left;
}

.hero-title h1 {
  position: relative;
  font-weight: 500;
  font-size: var(--hero-h1);
  margin-bottom: 0.8rem;
  line-height: 1.4;
}

.hero-title h2 {
  font-weight: 400;
  font-size: var(--font-lg);
  margin-bottom: 1.7rem;
}


.hero-btn {
  font-family: 'Poppins', sans-serif;
  font-size: var(--font-md);
  font-weight: 400;
  padding: 10px 22px;
  border: none;
  border-radius: 25px;
  color: #1D1C1C;
  background-color: var(--yellow);
  background-image: linear-gradient(270deg, var(--red) 50%, transparent 50%);
  background-size: 500px;
  background-position: 100%;
  transition: background 300ms ease-in-out;

}

/* animations */

/* button */

.hero-btn-overlay:hover {
  background-position: 0%;
  color: #1D1C1C;
}

.scale-in-left {
  animation: scale-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s both;
}

@keyframes scale-in-left {
  0% {
    transform: scale(0);
    transform-origin: 0% 50%;
    opacity: 1;
  }

  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
    opacity: 1;
  }
}

/* h1 and h3 */

.slide-in-left-hero-title {
  animation: slide-in-left-hero-title 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-in-left-hero-title {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }

  30% {
    opacity: 0.3;
  }

  50% {
    opacity: 0.5;
  }

  70% {
    opacity: 0.7;
  }

  85% {
    opacity: 0.85;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Changing text animation */

.bounce-in-right {
  animation: bounce-in-right 2s both;
}

@keyframes bounce-in-right {
  0% {
    transform: translateX(600px);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  38% {
    transform: translateX(0);
    animation-timing-function: ease-out;
    opacity: 1;
  }

  55% {
    transform: translateX(68px);
    animation-timing-function: ease-in;
  }

  72% {
    transform: translateX(0);
    animation-timing-function: ease-out;
  }

  81% {
    transform: translateX(32px);
    animation-timing-function: ease-in;
  }

  90% {
    transform: translateX(0);
    animation-timing-function: ease-out;
  }

  95% {
    transform: translateX(8px);
    animation-timing-function: ease-in;
  }

  100% {
    transform: translateX(0);
    animation-timing-function: ease-out;
  }
}

.slide-in-elliptic-top-fwd {
  animation: slide-in-elliptic-top-fwd 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-in-elliptic-top-fwd {
  0% {
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform-origin: 50% 100%;
    opacity: 0;
  }

  100% {
    transform: translateY(0) rotateX(0) scale(1);
    transform-origin: 50% 1400px;
    opacity: 1;
  }
}

/* mobile responsiveness */

@media(max-width: 480px) {
  .hero {
    padding: var(--mobile-padding);
    position: relative;
    padding-bottom: 0;
    min-height: 80vh;
  }

  .hero-title {
    padding-top: 0rem;
    margin-top: 5rem;
  }

  .hero-title h1 {
    width: 100%;
    position: relative;
    font-weight: 500;
    font-size: 2rem;
    margin-bottom: 1rem;
    margin-top: 3rem;
    text-align: left;
  }

  .hero-title h2 {
    text-align: left;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .hero-btn {
    font-size: 1rem;
  }

}

@media screen and (min-width: 481px) and (max-width: 768px) {

  .hero {
    padding: var(--tablet-padding);
    padding-bottom: 0;
    min-height: 90vh;
  }


  .hero-title h1 {
    width: 100%;
    position: relative;
    font-weight: 500;
    font-size: 3rem;
    margin-bottom: 1rem;
    text-align: left;
  }

  .hero-title h3 {
    text-align: left;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {

  .hero {
    padding: var(--tablet-padding);
    min-height: 100vh;
    padding-bottom: 12rem;
  }
}