nav {
  position: sticky;
  top: 0;
  z-index: 2;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--primary-text-color);
  padding: 0rem 3rem;
}

/* logo */
.logo {
  height: 20px;
  z-index: 3;
  cursor: pointer;
}

.links-container {
  display: flex;
  gap: 4.25rem;
}

.links-container li {
  color: white;
  cursor: pointer;
  font-size: var(--font-md);
  transition: 0.2s;
  list-style: none;
  z-index: 1;
}

.links-container li:hover {
  color: var(--red)
}

.links-container .active-link {
  color: var(--red)
}

.nav-burger,
.nav-close-btn {
  display: none;
  z-index: 3;
  font-size: 1.5rem;
}

/* mobile menu animations */

.slide-in-right-nav {
  animation: slide-in-right-nav 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-in-right-nav {
  0% {
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

/* mobile and tablet responsiveness */

@media(max-width: 480px) {
  .nav {
    padding: var(--mobile-padding);
    align-items: baseline;
    padding-bottom: 0;
  }

  .links-container.hidden {
    display: none;
  }

  .links-container.show {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: left;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    right: 0;
    top: 0;
    padding: 6rem 3rem;
    padding-bottom: 4rem;
    z-index: 2;
    background-color: #1d1c1cf0;
  }

  .nav-burger,
  .nav-close-btn {
    display: block;
  }

  .nav-close-btn {
    font-weight: bold;
    color: var(--primary-text-color)
  }

  .nav p {
    font-size: 1.2rem;
  }

  .nav ul {
    flex-direction: column;
    z-index: 10;
    gap: 1.5rem
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .nav {
    padding: var(--tablet-padding);

  }

  .links-container.hidden {
    display: none;
  }

  .links-container.show {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: left;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    right: 0;
    top: 0;
    padding: 8rem 3rem;
    padding-bottom: 4rem;
    background-color: #1d1c1cf0;
    ;
  }

  .nav-burger,
  .nav-close-btn {
    display: block;
  }

  .nav-close-btn {
    font-weight: bold;
    color: var(--primary-text-color)
  }

  .nav {
    align-items: baseline;
    padding-bottom: 0;
  }

  .nav ul {
    flex-direction: column;
    z-index: 10;
    gap: 1.5rem
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .nav {
    padding: var(--tablet-padding);
  }
}